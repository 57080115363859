
.cardWrapper {
    padding-top: 30px;
}

.cardBody {
    min-height: 130px;
    border-radius: 12px;
    box-shadow: 2px 2px 8px #ABB2B9;
    text-align: center;
    display: block;
}

.cardTitle {
    font-size: 16px;
    display: block;
    width: 100%;
}

.cardCount {
    font-size: 30px;
    font-weight: 600;
    display: block;
}

.cardIcon {
    font-size: 25px;
    width: 55px;
    height: 55px;
    padding: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-top: -30px;
    border: 2px solid #fff;
}

.cardFooter {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    padding: 2px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.pinkBackground {
    background-color: #fad0c4;
    background-image: linear-gradient(315deg, #fad0c4 0%, #f1a7f1 74%);
}

.pinkFont {
    color: #50274C;
}

.blackBackground {
    background-color: #130f40;
    background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
}

.whiteFont {
    color: #E7E3E6;
}

.blueBackground {
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
}

.yellowBackground {
    background-color: #c11d38;
    background-image: linear-gradient(315deg, #c11d38 0%, #ffc857 74%);
}

.greenBackground {
    background-color: #20bf55;
    background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);

}

.redBackground {
    background-color: #3f0d12;
    background-image: linear-gradient(315deg, #3f0d12 0%, #a71d31 74%);

}

.levenderBackground {
    background-color: #726cf8;
    background-image: linear-gradient(315deg, #726cf8 0%, #e975a8 74%);

}

