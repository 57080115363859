.masterWrapper{
    background-color: #e6e8fb;   
    padding: 8px;    
}

.innerBox{
    background-color: #F4F6F6;
    padding: 8px;    
    box-shadow: 0px 5px 15px #D5D8DC;
    border: 1px solid #EAECEE;
    margin-top: 8px;
    margin-bottom: 16px;
}